import { ComponentType } from './ComponentType';
import { ItEventType } from './ItEventType';

export class FeatureUsageEvent {

    private eventName: string;
    private duration: number;
    private componentUri: string;
    private eventType: ItEventType;
    private componentType: ComponentType;
    private xcv: string;
    private userRoleName: string;
    private startDateTime: Date;
    private endDateTime: Date;
    private templateType: string;

    constructor(eventName: string, componentType: ComponentType)
    constructor(eventName: string, componentType: ComponentType, startDateTime: Date, endDateTime: Date)
    constructor(eventName: string, componentType: ComponentType, startDateTime?: Date, endDateTime?: Date) {
        this.EventName = eventName;
        this.ComponentType = componentType;
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
    }

    public get EventName(): string {
        return this.eventName;
    }

    public set EventName(value: string) {
        this.eventName = value;
    }

    public get Duration(): number {
        return this.duration;
    }

    public set Duration(value: number) {
        this.duration = value;
    }

    public get ComponentUri(): string {
        return this.componentUri;
    }

    public set ComponentUri(value: string) {
        this.componentUri = value;
    }

    public get EventType(): ItEventType {
        return this.eventType;
    }

    public get TemplateType(): string {
        return "Internal.FeatureUsageEvent";
    }

    public get ComponentType(): ComponentType {
        return this.componentType;
    }

    public set ComponentType(value: ComponentType) {
        this.componentType = value;
    }

    public get Xcv(): string {
        return this.xcv;
    }

    public set Xcv(value: string) {
        this.xcv = value;
    }

    public get UserRoleName(): string {
        return this.userRoleName;
    }

    public set UserRoleName(value: string) {
        this.userRoleName = value;
    }

    public get StartDateTime(): Date {
        return this.startDateTime;
    }

    public set StartDateTime(value: Date) {
        this.startDateTime = value;
    }

    public get EndDateTime(): Date {
        return this.endDateTime;
    }

    public set EndDateTime(value: Date) {
        this.endDateTime = value;
    }

    public IsValidEvent(isValid: boolean): boolean {
        if (!this.eventName || 0 === this.eventName.length) {
            return false;
        }
        else {
            return true;
        }
    }
}   
