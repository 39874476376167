export enum ComponentType {
  Web,
  SmartApp,
  Device,
  BackgroundProcess,
  WebService,
  Executable,
  DynamicLinkLibrary,
  JobService,
  WorkflowComponent,
  DataStore,
  Other
}
