import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { FeatureUsageEvent } from './contracts/FeatureUsageEvent';
import { BusinessProcessEvent } from './contracts/BusinessProcessEvent';
import { SystemEvent } from './contracts/SystemEvent';
import { EnvironmentValues } from './contracts/EnvironmentValues';
import { ComponentType } from './contracts/ComponentType';
import { ItEventType } from './contracts/ItEventType';
import { getISO8601String } from './utils/utils';
import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import {BaseEvent} from './contracts/BaseEvent';
import {BpmValidation} from './contracts/BpmValidation';
import {CorrelationConstants} from './contracts/CorrelationConstants';
import {ExtensionsConfig} from './contracts/ExtensionsConfig';
import {ItTelemetryConfiguration} from './contracts/ItTelemetryConfiguration';
import {SerializationHelper} from './contracts/SerializationHelper';



export var ExtensionVersion = "npm:2.5.5-build0610";

export class AppInsights {
    ItTelemetryConfig: any;
    environmentData: any;

    public InitializeTelemetry(environmentName: string, iKey: string, serviceOffering: string, serviceLine: string, service: string, componentName: string, componentId: string, ictoid: string) {
        var appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: iKey,
				maxAjaxCallsPerView: -1,
				isCookieUseDisabled: true
            }
        });

        this.environmentData = new EnvironmentValues(
            environmentName,
            serviceOffering,
            serviceLine,
            service,
            componentName,
            componentId,
            ictoid
        );

        //Issue while loading common schema properties via Custom Telemetry-Initializer
        //Thus, commenting this piece of code, and adding common-schema properties in specific TrackEvents itself.
        //this.AddMsitTelemetryInitializers(appInsights, environmentData, null);

        appInsights.loadAppInsights();


        return appInsights;
    }

    public TrackPageview(appInsights: ApplicationInsights, properties?: Object) {
        properties = this.PopulateCommonSchemaValues(appInsights, properties);
        appInsights.trackPageView({properties: properties});
    }

    public Flush(appInsights: ApplicationInsights) {
        appInsights.flush();
    }

    public TrackEvent(appInsights: ApplicationInsights, eventName: string, properties?: Object, measurements?: any) {
        properties = this.PopulateCommonSchemaValues(appInsights, properties);

        if (appInsights != null && eventName != null) {
            appInsights.trackEvent({ name: eventName, properties: properties, measurements: measurements });
        }
    }

    public TrackTrace(appInsights: ApplicationInsights, message: string, severityLevel?: any, properties?: Object) {
        properties = this.PopulateCommonSchemaValues(appInsights, properties);

        if (appInsights != null && message != null) {
            appInsights.trackTrace({ message: message, properties: properties, severityLevel: severityLevel });
        }
    }

    public TrackException(appInsights: ApplicationInsights, error: Error, severityLevel?: any, properties?: Object, measurements?: any) {
        properties = this.PopulateCommonSchemaValues(appInsights, properties);

        if (appInsights != null && error != null) {
            appInsights.trackException({ error: error, severityLevel: severityLevel, properties: properties, measurements: measurements });
        }
    }

    private PopulateCommonSchemaValues(appInsights: ApplicationInsights, properties?: Object) {
        properties = properties || {};
        properties["AiAppKey"] = appInsights.config.instrumentationKey == null ? "" : appInsights.config.instrumentationKey;
        properties["EnvironmentName"] = this.environmentData.EnvironmetName == null ? "" : this.environmentData.EnvironmetName;
        properties["ServiceOffering"] = this.environmentData.ServiceOffering == null ? "" : this.environmentData.ServiceOffering;
        properties["ServiceLine"] = this.environmentData.ServiceLine == null ? "" : this.environmentData.ServiceLine;
        properties["Service"] = this.environmentData.Service == null ? "" : this.environmentData.Service;
        properties["ComponentName"] = this.environmentData.ComponentName == null ? "" : this.environmentData.ComponentName;
        properties["ComponentId"] = this.environmentData.ComponentId == null ? "" : this.environmentData.ComponentId;
        properties["ItExtVersion"] = ExtensionVersion;

        return properties;
    }

    public TrackFeatureUsageEvent(appInsights: ApplicationInsights, eventData: FeatureUsageEvent, properties?: Object, measurements?: any) {
        properties = this.PopulateCommonSchemaValues(appInsights, properties);

        if (appInsights != null && eventData != null) {
            var isTrackable = this.isValidEvent(eventData, properties);

            if (isTrackable) {
                properties = this.BuildFeatureEventProperties(eventData, properties);
                appInsights.trackEvent({ name: eventData.EventName, properties: properties, measurements: measurements });
            }
            else {
                throw "One or more attributes in the in the event Feature Usage Event are not valid";
            }
        }
    }

    public TrackBusinessProcessEvent(appInsights: ApplicationInsights, eventData: BusinessProcessEvent, properties?: Object, measurements?: any) {
        properties = this.PopulateCommonSchemaValues(appInsights, properties);

        if (appInsights != null && eventData != null) {
            var isTrackable = this.isValidEvent(eventData, properties);

            if (isTrackable) {
                properties = this.BuildBusinessEventProperties(eventData, properties);
                appInsights.trackEvent({ name: eventData.BusinessProcessName, properties: properties, measurements: measurements });
            }
            else {
                throw "One or more attributes in the event Business Process Event are not valid";
            }
        }
    }

    public TrackSystemEvent(appInsights: ApplicationInsights, eventData: SystemEvent, properties?: Object, measurements?: any) {
        properties = this.PopulateCommonSchemaValues(appInsights, properties);

        if (appInsights != null && eventData != null) {
            var isTrackable = this.isValidEvent(eventData, properties);

            if (isTrackable) {
                properties = this.BuildSystemEventProperties(eventData, properties);
                appInsights.trackEvent({ name: eventData.BusinessProcessName, properties: properties, measurements: measurements });
            }
            else {
                throw "One or more attributes in the event System Event are not valid";
            }
        }
    }

    public AddMsitTelemetryInitializers(appInsights: ApplicationInsights, environmentData: EnvironmentValues, correlationProvider: () => string) {
        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
            try {
                envelope
                envelope.baseData.properties = envelope.baseData.properties || {};
                envelope.baseData.properties["AiAppKey"] = appInsights.config.instrumentationKey;

                if (environmentData != null) {
                    envelope.baseData.properties["EnvironmentName"] = environmentData.EnvironmetName == null ? "" : environmentData.EnvironmetName;
                    envelope.baseData.properties["ServiceOffering"] = environmentData.ServiceOffering == null ? "" : environmentData.ServiceOffering;
                    envelope.baseData.properties["ServiceLine"] = environmentData.ServiceLine == null ? "" : environmentData.ServiceLine;
                    envelope.baseData.properties["Service"] = environmentData.Service == null ? "" : environmentData.Service;
                    envelope.baseData.properties["ComponentName"] = environmentData.ComponentName == null ? "" : environmentData.ComponentName;
                    envelope.baseData.properties["ComponentId"] = environmentData.ComponentId == null ? "" : environmentData.ComponentId;
                    if (environmentData.IctoId != null && environmentData.IctoId !== "") {
                        envelope.baseData.properties["IctoId"] = environmentData.IctoId;
                    }
                    envelope.baseData.properties["ItExtVersion"] = ExtensionVersion;
                }

                var correlationId = "";
                if (correlationProvider != null)
                    correlationId = correlationProvider();

                if (correlationId != null && correlationId !== "") {
                    envelope.baseData.properties["XCV"] = correlationId;
                }
            }
            catch (ex) {

            }
        });
    }

    public AddMsitTelemetryInitializerswithSubCorrelation(appInsights: ApplicationInsights, environmentData: EnvironmentValues, correlationProvider: () => string, subCorrelationProvider: () => string) {
        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
            envelope.baseData.properties = envelope.baseData.properties || {};
            envelope.baseData.properties["AiAppKey"] = appInsights.config.instrumentationKey;

            if (environmentData != null) {
                envelope.baseData.properties["EnvironmentName"] = environmentData.EnvironmetName == null ? "" : environmentData.EnvironmetName;
                envelope.baseData.properties["ServiceOffering"] = environmentData.ServiceOffering == null ? "" : environmentData.ServiceOffering;
                envelope.baseData.properties["ServiceLine"] = environmentData.ServiceLine == null ? "" : environmentData.ServiceLine;
                envelope.baseData.properties["Service"] = environmentData.Service == null ? "" : environmentData.Service;
                envelope.baseData.properties["ComponentName"] = environmentData.ComponentName == null ? "" : environmentData.ComponentName;
                envelope.baseData.properties["ComponentId"] = environmentData.ComponentId == null ? "" : environmentData.ComponentId;
                envelope.baseData.properties["ItExtVersion"] = ExtensionVersion;
            }

            var correlationId = "";
            if (correlationProvider != null)
                correlationId = correlationProvider();

            if (correlationId != null && correlationId !== "") {
                envelope.baseData.properties["XCV"] = correlationId;
            }

            var subcorrelationId = "";
            if (subCorrelationProvider != null)
                correlationId = subCorrelationProvider();

            if (subcorrelationId != null && subcorrelationId !== "") {
                envelope.baseData.properties["SubXCV"] = subcorrelationId;
            }

        });
    }

    private BuildFeatureEventProperties(eventData: FeatureUsageEvent, properties?: Object) {
        var eventProperties;
        eventProperties = properties || {};

        eventProperties["ComponentUri"] = eventData.ComponentUri === undefined ? "" : eventData.ComponentUri;
        eventProperties["Duration "] = eventData.Duration === undefined ? null : eventData.Duration;
        eventProperties["ComponentType"] = (eventData.ComponentType !== undefined && eventData.ComponentType != null) ? ComponentType[eventData.ComponentType].toString() : "";
        eventProperties["UserRoleName"] = eventData.UserRoleName === undefined ? "" : eventData.UserRoleName;
        eventProperties["XCV"] = eventData.Xcv === undefined ? "" : eventData.Xcv;
        eventProperties["EventType"] = eventData.EventType !== undefined && eventData.EventType != null ? ItEventType[eventData.EventType].toString() : "";
        eventProperties["StartDateTime"] = eventData.StartDateTime === undefined || eventData.StartDateTime === null ? "" : eventData.StartDateTime.toUTCString();
        eventProperties["EndDateTime"] = eventData.EndDateTime === undefined || eventData.EndDateTime === null ? "" : eventData.EndDateTime.toUTCString();
        eventProperties["TemplateType"] = eventData.TemplateType === undefined || eventData.TemplateType === null ? "" : eventData.TemplateType;
        eventProperties["MsitPartB"] = "true";

        return eventProperties;
    }

    private BuildBusinessEventProperties(eventData: BusinessProcessEvent, properties?: Object) {
        var eventProperties;
        eventProperties = properties || {};

        eventProperties["ActionUri"] = eventData.ActionUri === undefined ? "" : eventData.ActionUri;
        eventProperties["AppAction"] = eventData.AppAction === undefined ? "" : eventData.AppAction;
        eventProperties["ComponentType"] = (eventData.ComponentType !== undefined && eventData.ComponentType != null) ? ComponentType[eventData.ComponentType].toString() : "";
        eventProperties["TargetEntityKey"] = eventData.TargetEntityKey === undefined ? "" : eventData.TargetEntityKey;
        eventProperties["UserRoleName"] = eventData.UserRoleName === undefined ? "" : eventData.UserRoleName;
        eventProperties["XCV"] = eventData.Xcv === undefined ? "" : eventData.Xcv;
        eventProperties["EventType"] = eventData.EventType !== undefined && eventData.EventType != null ? ItEventType[eventData.EventType].toString() : "";
        eventProperties["StartDateTime"] = eventData.StartDateTime === undefined || eventData.StartDateTime === null ? "" : eventData.StartDateTime.toUTCString();
        eventProperties["EndDateTime"] = eventData.EndDateTime === undefined || eventData.EndDateTime === null ? "" : eventData.EndDateTime.toUTCString();
        eventProperties["SenderId"] = eventData.SenderId === undefined ? "" : eventData.SenderId;
        eventProperties["ReceiverID"] = eventData.ReceiverId === undefined ? "" : eventData.ReceiverId;
        eventProperties["MessageId"] = eventData.MessageId === undefined ? "" : eventData.MessageId;
        eventProperties["EventOccurrenceTime"] = eventData.EventOccurrenceTime === undefined || eventData.EventOccurrenceTime === null || isNaN(eventData.EventOccurrenceTime.getDate()) ? "" : getISO8601String(new Date(eventData.EventOccurrenceTime.toString()));
        eventProperties["BusinessProcessName"] = eventData.BusinessProcessName === undefined ? "" : eventData.BusinessProcessName;
        eventProperties["TemplateType"] = eventData.TemplateType === undefined || eventData.TemplateType === null ? "" : eventData.TemplateType;
        eventProperties["MsitPartB"] = "true";

        return eventProperties;
    }

    private BuildSystemEventProperties(eventData: SystemEvent, properties?: Object) {
        var eventProperties;
        eventProperties = properties || {};

        eventProperties["ActionUri"] = eventData.ActionUri === undefined ? "" : eventData.ActionUri;
        eventProperties["AppAction"] = eventData.AppAction === undefined ? "" : eventData.AppAction;
        eventProperties["ComponentType"] = (eventData.ComponentType !== undefined && eventData.ComponentType != null) ? ComponentType[eventData.ComponentType].toString() : "";
        eventProperties["TargetEntityKey"] = eventData.TargetEntityKey === undefined ? "" : eventData.TargetEntityKey;
        eventProperties["UserRoleName"] = eventData.UserRoleName === undefined ? "" : eventData.UserRoleName;
        eventProperties["XCV"] = eventData.Xcv === undefined ? "" : eventData.Xcv;
        eventProperties["EventType"] = eventData.EventType !== undefined && eventData.EventType != null ? ItEventType[eventData.EventType].toString() : "";
        eventProperties["StartDateTime"] = eventData.StartDateTime === undefined || eventData.StartDateTime === null ? "" : eventData.StartDateTime.toUTCString();
        eventProperties["EndDateTime"] = eventData.EndDateTime === undefined || eventData.EndDateTime === null ? "" : eventData.EndDateTime.toUTCString();
        eventProperties["SenderId"] = eventData.SenderId === undefined ? "" : eventData.SenderId;
        eventProperties["ReceiverID"] = eventData.ReceiverId === undefined ? "" : eventData.ReceiverId;
        eventProperties["MessageId"] = eventData.MessageId === undefined ? "" : eventData.MessageId;
        eventProperties["EventOccurrenceTime"] = eventData.EventOccurrenceTime === undefined || eventData.EventOccurrenceTime === null || isNaN(eventData.EventOccurrenceTime.getDate()) ? "" : getISO8601String(new Date(eventData.EventOccurrenceTime.toString()));
        eventProperties["BusinessProcessName"] = eventData.BusinessProcessName === undefined ? "" : eventData.BusinessProcessName;

        eventProperties["SystemMessage"] = eventData.SystemMessage === undefined || eventData.SystemMessage === null ? "" : eventData.SystemMessage;
        eventProperties["TemplateType"] = eventData.TemplateType === undefined || eventData.TemplateType === null ? "" : eventData.TemplateType;
        eventProperties["MsitPartB"] = "true";
        return eventProperties;
    }

    public isValidEvent(eventData: any, properties?: Object) {
        var config = this.ItTelemetryConfig;
        var isTrackable = eventData.IsValidEvent(false);


        if (config !== null && config !== undefined && config.active.EnableBpmValidation) {
            var bpmSpecificConfig = config.active.ReadBpmValidation(eventData.EventName);

            if (bpmSpecificConfig == null) {
                return isTrackable;
            }

            if (bpmSpecificConfig.PartBValidation) {
                isTrackable = eventData.IsValidEvent(bpmSpecificConfig.PartBValidation);
            }

            if (bpmSpecificConfig.Properties != null) {
                var keys = Object.keys(properties);
                var bpmPropertiesLocal = bpmSpecificConfig.Properties;
                var numberOfPropertiesFound = 0;

                for (var index = 0; index < bpmPropertiesLocal.length; index++) {
                    var item = bpmPropertiesLocal[index];
                    var check = keys.indexOf(item);
                    if (check >= 0) {
                        // Provided a property is found, it should have a value.
                        if (typeof properties[item] != 'undefined') {
                            numberOfPropertiesFound += 1;
                        }
                        else {
                            isTrackable = false;
                            break;
                        }
                    }
                }

                if (numberOfPropertiesFound == bpmPropertiesLocal.length && isTrackable != false) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        else {
            return isTrackable;
        }
    }
}



export {BaseEvent};
export {BpmValidation};
export {BusinessProcessEvent};
export {ComponentType};
export {CorrelationConstants};
export {EnvironmentValues};
export {ExtensionsConfig};
export {FeatureUsageEvent};
export {ItEventType};
export {ItTelemetryConfiguration};
export {SerializationHelper};
export {SystemEvent};