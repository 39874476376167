import { BusinessProcessEvent } from './BusinessProcessEvent';
import { ComponentType } from './ComponentType';
import { ItEventType } from './ItEventType';

export class SystemEvent extends BusinessProcessEvent {

    private systemMessage: string;

    constructor(businessProcessName: string, componentType: ComponentType)
    constructor(businessProcessName: string, componentType: ComponentType, systemMessage: string)
    constructor(businessProcessName: string, componentType: ComponentType, systemMessage?: string) {
        super(businessProcessName, componentType, null, null);
        this.systemMessage = systemMessage;
        this.EventType = ItEventType.SystemEvent;
    }

    public get SystemMessage(): string {
        return this.systemMessage;
    }

    public set SystemMessage(value: string) {
        this.systemMessage = value;
    }

    public IsValidEvent(isValid: boolean): boolean {
        if (!this.EventName || 0 === this.EventName.length) {
            return false;
        }
        else {
            return true;
        }
    }
}
