export function getISO8601String(givenDate: Date): string {
    var dateString = givenDate.toISOString();
    var dateWithoutZulu = dateString.substring(0, dateString.length - 1);
    // Computing the TZ offset to GMT String base +0530 / +0800 / -0300 etc..,
    function z(n) { return (n < 10 ? '0' : '') + n; }
    var offset = new Date().getTimezoneOffset();
    var sign = offset < 0 ? '+' : '-';
    offset = Math.abs(offset);
    return dateWithoutZulu + sign + z(offset / 60 | 0) + z(offset % 60);
}
