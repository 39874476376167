export class EnvironmentValues {
    /// Plain string that names the environment of the component. Default value is: Production.         
    ///IMPORTANT: The only valid value for this property in production is "Production".
    ///If this attribute value is something else other than "Production" data won't be pushed 
    ///to production clusters of MSIT Telemetry Store. -->
    public EnvironmetName: string;
    // Plain string, value contains Level 1 hierarchy of Service Tree (also known as ServiceGroup).
    public ServiceOffering: string;
    // Plain string, value contains Level 2 hierarchy of Service Tree (also known as TeamGroup).
    public ServiceLine: string;
    // Plain string, value contains Level 3 hierarchy of Service Tree.
    public Service: string;
    // Plain string, value contains Level 4 hierarchy of Service Tree.
    public ComponentName: string;
    // Plain string, value contains Service Tree Component Id (Guid) value.
    public ComponentId: string;
    // Plain string, value contains ictoId
    public IctoId: string

    constructor(environmentName: string, serviceOffering: string, serviceLine: string, service: string, componentName: string, componentId: string, ictoId: string) {
        this.EnvironmetName = environmentName;
        this.ServiceOffering = serviceOffering;
        this.ServiceLine = serviceLine;
        this.Service = service;
        this.ComponentName = componentName;
        this.ComponentId = componentId;
        this.IctoId = ictoId;
    }
}