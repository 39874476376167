import { SerializationHelper } from './SerializationHelper';
import { ExtensionsConfig } from './ExtensionsConfig';
import { BpmValidation } from './BpmValidation';

export class ItTelemetryConfiguration extends SerializationHelper {
    private active: ExtensionsConfig;

    IsJsonString(str): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    Initialize(ECObject: any): void {
        this.active = SerializationHelper.toInstance(new ExtensionsConfig(), ECObject);
    }

    // GET
    public get getActive(): any {
        return this.active;
    }

    public get getBpmValidations(): BpmValidation[] {
        return this.getActive.BpmValidations;
    }

    public get getEnableBpmValidation(): boolean {
        return this.getActive.EnableBpmValidation;
    }

    public ReadBpmValidation(bpmName: string): any {
        var defObject = null;
        if (bpmName != null && typeof bpmName != 'undefined' && this.getBpmValidations != null && this.getBpmValidations.length > 0) {
            var bpmValidationsArray = this.getBpmValidations;
            for (var index = 0; index < bpmValidationsArray.length; index++) {
                if (bpmValidationsArray[index].BusinessProcessName == bpmName) {
                    return bpmValidationsArray[index];
                }
            }
        }
        return defObject;
    }

}