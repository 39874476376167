export class BpmValidation {
    private businessProcessName: string;
    private partBValidation: boolean;
    private properties: string[];

    constructor(businessProcessName: string, partBValidation: boolean, properties?: string[]) {
        this.businessProcessName = businessProcessName;
        this.partBValidation = partBValidation;
        this.properties = properties;
    }

    // GET methods
    public get BusinessProcessName(): string {
        return this.businessProcessName;
    }

    public get PartBValidation(): boolean {
        return this.partBValidation;
    }

    public get Properties(): string[] {
        return this.properties;
    }

    // SET methods
    public set BusinessProcessName(value: string) {
        this.businessProcessName = value;
    }

    public set PartBValidation(value: boolean) {
        this.PartBValidation = value;
    }

    public set Properties(values: string[]) {
        this.properties = values;
    }

}