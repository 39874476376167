import { BpmValidation } from './BpmValidation';

export class ExtensionsConfig {
    private EnableBpmValidation: boolean;
    private bpmValidations: any[];

    public get getBpmValidations(): BpmValidation[] {
        return this.bpmValidations;
    }

    public get getEnableBpmValidation(): boolean {
        return this.EnableBpmValidation;
    }

    public ReadBpmValidation(bpmName: string) {
        var defObject = null;
        if (bpmName != null && typeof bpmName != 'undefined' && this['BpmValidations'] != null && this['BpmValidations'].length > 0) {
            var bpmValidationsArray = this['BpmValidations'];
            for (var index = 0; index < bpmValidationsArray.length; index++) {
                if (bpmValidationsArray[index].BusinessProcessName == bpmName) {
                    return bpmValidationsArray[index];
                }
            }
        }
        return defObject;
    }
}