import { ComponentType } from './ComponentType';
import { ItEventType } from './ItEventType';

export class BaseEvent {
    eventType: ItEventType;
    componentType: ComponentType;
    xcv: string;
    userRoleName: string;
    startDateTime: Date;
    endDateTime: Date;
    senderId: string;
    receiverId: string;
    messageId: string;
    eventOccurenceTime: Date;
    templateType: string;
}